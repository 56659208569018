
import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';

import Isvg from 'react-inlinesvg';

import {
    Col,
    Row,
    Container
} from 'reactstrap';

class List extends Component {
    constructor(props) {
        super(props);
        this.state = {

        };
    }

    render() {
        if (!this.props.value.length)
            return null
        return (
            <div className="list-view">
                <Container fluid>

                    {
                        this.props.value && this.props.value.length ? this.props.value.map((result, idx1) => {
                            return (
                                <Row key={idx1}>
                                    {
                                        this.props.fields.map((field, idx2) => {
                                            let broken = field.name.split('.');
                                            let value = result;

                                            for (let i = 0; i < broken.length; i++) {
                                                value = value[broken[i]];
                                            }

                                            if (field.type === 'image') {
                                                return (<Col key={idx2} lg={field.width.desktop} xs={field.width.mobile}><img src={value} /></Col>)
                                            } else {
                                                return (<Col key={idx2} lg={field.width.desktop} xs={field.width.mobile}>{value} {field.afterText ? field.afterText : ''}</Col>)
                                            }
                                        })
                                    }

                                    {!this.props.disableDelete ?
                                        <Col lg={1} xs={1}><i onClick={() => {
                                            let value = this.props.value.slice(0, idx1).concat(this.props.value.slice(idx1 + 1, this.props.value.length));
                                            this.props.onChange(value);

                                        }} className="mdi mdi-delete"></i></Col>
                                        :
                                        null
                                    }
                                </Row>
                            )
                        })

                            : null
                    }
                </Container>

            </div>
        );
    }
}

export default List;