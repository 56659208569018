import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { PageWithLayout } from '../containers/page';


class SettingsPage extends Component {
    constructor(props) {
        super(props);

        this.registerSocketIOEvents = this.registerSocketIOEvents.bind(this);
        this.updateSettings = this.updateSettings.bind(this);
        this.initMap = this.initMap.bind(this);
        this.trackChangeLocation = this.trackChangeLocation.bind(this);

        this.state = {
            location: '44.75874,19.21437'
        };
    }


    trackChangeLocation() {
        this.setState({
            location: this.state.googleMap.getCenter().lat() + ', ' + this.state.googleMap.getCenter().lng()
        });
    }

    initMap() {
        console.log("InitMAP");
        this.setState({
            _mapInit: true
        });
        var latLng = new window.google.maps.LatLng(this.state.location.split(',')[0], this.state.location.split(',')[1]);

        var map = new window.google.maps.Map(this.GoogleMap, {
            zoom: 14,
            center: latLng,
            mapTypeId: window.google.maps.MapTypeId.ROADMAP,
            disableDefaultUI: true,
            gestureHandling: "greedy",
        });

        map.addListener('center_changed', this.trackChangeLocation);

        /*window.google.maps.event.addListenerOnce(map, 'idle', function () {
            window.google.maps.event.trigger(map, 'resize');
            map.setCenter(latLng);
        });

        let scope = this;
        map.setCenter(latLng);
        */
        this.setState({ googleMap: map });
    }

    componentDidMount() {
        this.props.handleLoader(true);

        if (this.props.socketIOClient) {
            this.registerSocketIOEvents();
        }

        if (this.props._googleMapsLoaded && !this.state._mapInit) {
            this.initMap();
        }

    }

    componentDidUpdate(prevProps) {
        if (this.props.socketIOClient) {
            this.registerSocketIOEvents();
        }

        if (this.props._googleMapsLoaded && !this.state._mapInit) {
            this.initMap();
        }
    }


    registerSocketIOEvents() {
        if (this.state._registeredEvents)
            return;

        this.setState({
            _registeredEvents: true
        });

        this.props.socketIOClient.on('updateSettings', (data) => {
            if (data.successful) {
                this.props.handleLoader(null);

            }
        });

        this.props.socketIOClient.on('settings', (data) => {
            this.props.handleLoader(null);

            console.log(data);
            this.setState({
                ...data
            }, () => {
                var latLng = new window.google.maps.LatLng(this.state.location.split(',')[0], this.state.location.split(',')[1]);
                this.state.googleMap.setCenter(latLng);

            });
        });



        this.props.socketIOClient.emit('settings', {});



    }


    componentWillUnmount() {
        if (!this.props.socketIOClient) return;
        this.props.socketIOClient.removeAllListeners("settings");
    }

    updateSettings() {
        let data = {
            title: this.state.title,
            address: this.state.address,
            mobilePhone1: this.state.mobilePhone1,
            mobilePhone2: this.state.mobilePhone2,
            telephone: this.state.telephone,
            email: this.state.email,
            location: this.state.location,
            metaDescription: this.state.metaDescription,
            metaTags: this.state.metaTags
        };
        this.props.handleLoader(true);
        this.props.socketIOClient.emit("updateSettings", data);
    }









    render() {

        return (
            <div className="content">
                {

                    (!this.props.uData && !localStorage.uData) && <Redirect to='/login' />

                }
                <div className="panel">
                    <div className="panel-title">
                        <h1>{this.props.translate('Site settings')}</h1>
                    </div>
                    <div>



                        <div className="input-wrap input-wrap-6">
                            <label>{this.props.translate('Page Title')}</label>
                            <input type="text" value={this.state.title} onChange={(e) => { this.setState({ title: e.target.value }) }} />
                        </div>

                        <div className="spacer"></div>

                        <div className="input-wrap">
                            <label>{this.props.translate('Address')}</label>
                            <input type="text" value={this.state.address} onChange={(e) => { this.setState({ address: e.target.value }) }} />
                        </div>

                        <div className="input-wrap input-wrap-4">
                            <label>{this.props.translate('Mobile Phone1')}</label>
                            <input type="text" value={this.state.mobilePhone1} onChange={(e) => { this.setState({ mobilePhone1: e.target.value }) }} />
                        </div>

                        <div className="input-wrap input-wrap-4">
                            <label>{this.props.translate('Mobile Phone2')}</label>
                            <input type="text" value={this.state.mobilePhone2} onChange={(e) => { this.setState({ mobilePhone2: e.target.value }) }} />
                        </div>


                        <div className="input-wrap input-wrap-4">
                            <label>{this.props.translate('Telephone')}</label>
                            <input type="text" value={this.state.telephone} onChange={(e) => { this.setState({ telephone: e.target.value }) }} />
                        </div>

                        <div className="input-wrap input-wrap-6">
                            <label>{this.props.translate('E-Mail')}</label>
                            <input type="text" value={this.state.email} onChange={(e) => { this.setState({ email: e.target.value }) }} />
                        </div>



                        <div className="input-wrap">
                            <label>{this.props.translate('Location')} {this.state.location}</label>

                            {
                                this.props._googleMapsLoaded ?
                                    <div style={{ width: "100%", height: "400px" }} ref={(input) => { this.GoogleMap = input; }}>

                                    </div>

                                    :

                                    null
                            }

                            <div className="google-map-marker">
                                <i className="mdi mdi-map-marker"></i>
                            </div>


                        </div>

                        <div className="input-wrap input-wrap-6">
                            <label>{this.props.translate('Meta Description')}</label>
                            <textarea value={this.state.metaDescription} onChange={(e) => { this.setState({ metaDescription: e.target.value }) }}>

                            </textarea>
                        </div>

                        <div className="input-wrap input-wrap-6">
                            <label>{this.props.translate('Meta Tags')}</label>
                            <textarea value={this.state.metaTags} onChange={(e) => { this.setState({ metaTags: e.target.value }) }}>

                            </textarea>
                        </div>




                        <div className="input-wrap">
                            <button className="button" onClick={this.updateSettings}>{this.props.translate('Save')}</button>
                        </div>


                    </div>
                </div>


            </div>
        );
    }
}

export default PageWithLayout(SettingsPage);