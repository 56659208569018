
import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
const bcrypt = require('bcryptjs');


class Password extends Component {
    constructor(props) {
        super(props);

        this.state = {
        };
    }






    render() {

        return (
            <div className="input-wrap">
                <label>{this.props.translate(this.props.label)}</label>
                <input className={this.props.error ? 'required' : null} type='text' onChange={(e) => {


                    this.props.onChange(bcrypt.hashSync(e.target.value, bcrypt.genSaltSync(10)));

                    this.forceUpdate();


                }}  placeholder={this.props.placeholder} />
            </div>
        );
    }
}

export default Password;